<template>
  <div class="multiElevatorReport">
    <div class="reportContent" v-loading="loading">
      <!-- 大标题 -->
      <div class="title">
        <span>电梯AI维保系统多梯统计报告</span>
        <el-button class="downLoadBtn" @click="downLoad">下载</el-button>
      </div>
      <!-- 统计周期 -->
      <div class="reportDate">
        <span class="range">统计周期</span>
        <el-date-picker v-model="reportDate" class="datePicker" value-format="yyyy-MM-dd" :clearable="false"
          type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange"
          :picker-options="pickerOptions"></el-date-picker>
        <el-button class="createReport" @click="createReport">生成报告</el-button>
      </div>
      <!-- 摘要 -->
      <div class="abstract">
        <p class="blue" style="font-size: 20px;font-weight: 700;">{{ reportName }}</p>
        <p>[ 本期摘要 ]</p>
        <p>{{ abstract }}<el-button class="createReport" style="margin-left: 10px;" @click="edit">编辑</el-button></p>
        <p>1、{{ reportDate[0] }} 至 {{ reportDate[1] }}，本期多梯共运行 <span class="blue">{{ runningCountTotal }}</span> 次，运行距离
          <span class="blue">{{ runDistanceTotal.toFixed(2) }}</span> 千米，开关门 <span class="blue">{{
            doorOpenCountTotal }}</span> 次。
        </p>
        <p>2、运行时间最长的电梯是：{{ runningTimeMax ? runningTimeMax.name : "" }}，运行 <span class="blue">{{ runningTimeMax ? runningTimeMax.value : "" }}</span> 小时，堪称本次多梯中的劳模。
        </p>
        <p>3、运行次数最多的电梯是：{{ runningCountMax ? runningCountMax.name : "" }}，运行 <span class="blue">{{ runningCountMax ? runningCountMax.value : "" }}</span> 次，接送业主风雨无阻。
        </p>
        <p>4、运行距离最长的电梯是：{{ runDistanceMax ? runDistanceMax.name : "" }}，运行 <span class="blue">{{ runDistanceMax ? runDistanceMax.value : "" }}</span>
          千米，荣膺本次多梯中的长跑健将。</p>
        <p>5、累计发生故障 <span class="red">{{ faultCountTotal }}</span> 次，主要故障原因为:<span v-for="(item,index) in faultType"
            :key="item.id">{{ index === faultType.length - 1 ? item.name + "，" : item.name + "、" }} </span>维保工程师已经为您检查并修复。</p>
      </div>
      <!-- 阶段性电梯运行报告 -->
      <div class="smallTitle prevent-page-break-inside">
        <img style="width: 32px; height: 32px;margin-right: 12px;" src="../../assets/ai/dtyx@2x.png"/>
        <span>阶段性电梯运行报告</span>
      </div>
      <!-- 运行时间 -->
      <multi-run-time :data="runningTime" class="prevent-page-break-inside"></multi-run-time>
      <!-- 运行次数 -->
      <multi-run-count :data="runningCount" class="prevent-page-break-inside"></multi-run-count>
      <!-- 运行距离 -->
      <multi-run-distance :data="runDistance" class="prevent-page-break-inside"></multi-run-distance>
      <!-- 开关门次数 -->
      <multi-door-count :data="runDistance" class="prevent-page-break-inside"></multi-door-count>
      <!-- 终端在线率 -->
      <multi-online-rate :data="onlineRate" class="prevent-page-break-inside"></multi-online-rate>
      <!-- 健康指数 -->
      <multi-health :data="health" class="prevent-page-break-inside"></multi-health>
      <!-- 故障统计报告 -->
      <div class="smallTitle prevent-page-break-inside">
        <img style="width: 32px; height: 32px;margin-right: 12px;" src="../../assets/ai/gztj@2x.png"/>
        <span>故障统计报告</span>
      </div>
      <!-- 困人次数&故障停梯次数 -->
      <multi-fault-count :faultStop="faultStop" :faultTrap="faultTrap"
        class="prevent-page-break-inside"></multi-fault-count>
      <!-- 检修次数 -->
      <multi-repair-count :data="repairCount" class="prevent-page-break-inside"></multi-repair-count>
      <!-- 故障停梯时长&故障检修时长 -->
      <multi-fault-repair-time :faultStopTime="faultStopTime" :overhaulTime="overhaulTime"
        class="prevent-page-break-inside"></multi-fault-repair-time>
      <!-- 故障率 -->
      <multi-fault-rate class="prevent-page-break-inside" :data="faultRate"></multi-fault-rate>
      <!-- 故障类型 -->
      <multi-fault-rank :faultType="faultType" class="prevent-page-break-inside"></multi-fault-rank>
      <!-- 签字区 -->
      <div class="footer prevent-page-break-inside">
        <div class="signBox">
          <div>签字区</div>
          <div></div>
        </div>
      </div>
      <!-- 摘要编辑框 -->
      <el-dialog title="编辑摘要引言" :visible.sync="dialogVisible" width="50%">
        <el-input v-model="newAbstract" placeholder="请输入内容"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="sureHandle">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import MultiRunTime from "./echarts/MultiRunTime.vue";
import MultiRunCount from "./echarts/MultiRunCount.vue";
import MultiRunDistance from "./echarts/MultiRunDistance.vue";
import MultiDoorCount from "./echarts/MultiDoorCount.vue";
import MultiOnlineRate from "./echarts/MultiOnlineRate.vue";
import MultiHealth from "./echarts/MultiHealth.vue";
import MultiRepairCount from "./echarts/MultiRepairCount.vue";
import MultiFaultCount from "./echarts/MultiFaultCount.vue";
import MultiFaultRepairTime from "./echarts/MultiFaultRepairTime.vue";
import MultiFaultRank from "./echarts/MultiFaultRank.vue";
import MultiFaultRate from "./echarts/MultiFaultRate.vue";

export default {
  name: "MultiElevatorReport",
  components: { MultiRunTime, MultiRunCount, MultiRunDistance, MultiDoorCount, MultiOnlineRate, MultiHealth, MultiRepairCount, MultiFaultCount, MultiFaultRepairTime, MultiFaultRank, MultiFaultRate },
  data() {
    return {
      reportName: "",
      loading: false,
      elevatorIdSet: [],
      abstract: "请您查收多梯运行报告。电梯云守护您的电梯健康！",
      newAbstract: "",
      dialogVisible: false,
      elevatorRunInfo: {},
      runningTime: [],
      runningTimeMax: {},
      runningCount: [],
      runningCountMax: {},
      runningCountTotal: 0,
      runDistance: [],
      runDistanceMax: {},
      runDistanceTotal: 0,
      doorOpenCount: [],
      doorOpenCountTotal: 0,
      onlineRate: [],
      health: [],
      faultStop: [],
      faultTrap: [],
      repairCount: [],
      overhaulTime: [],
      faultStopTime: [],
      faultRate: [],
      faultType: [],
      faultCountTotal: 0,
      reportDate: [this.$moment().subtract(15, "days").format("YYYY-MM-DD"), this.$moment().subtract(1, "days").format("YYYY-MM-DD")], // 统计周期
      pickerOptions: {
        disabledDate(time) {
          // 禁用所有当前日期之后的日期
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: "最近一周",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "最近一个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "最近三个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          }
        }]
      },
    };
  },

  created() {
    this.elevatorIdSet = JSON.parse(this.$route.params.elevatorsIds);
    if (this.$route.params.reportName) {
      this.reportName = this.$route.params.reportName;
    }
    console.log(this.$route);
    this.getEchartDataByIds();
    this.getFaultType();
  },
  methods: {
    // 生成报告
    createReport() {
      this.runningTime = [];
      this.runningCount = [];
      this.runDistance = [];
      this.doorOpenCount = [];
      this.repairCount = [];
      this.onlineRate = [];
      this.health = [];
      this.faultStop = [];
      this.faultType = [];
      this.faultTrap = [];
      this.faultStopTime = [];
      this.overhaulTime = [];
      this.faultRate = [];
      this.getEchartDataByIds();
      this.getFaultType();
    },

    // 获取数据：运行时间、运行次数、运行距离、开关门次数、检修次数
    getEchartDataByIds() {
      this.loading = true;
      const params = {
        elevatorIdSet: this.elevatorIdSet,
        startTime: this.reportDate[0],
        endTime: this.reportDate[1]
      };
      this.$http.post("maintenanceReport/countReportByMultiple", params).then(res => {
        this.loading = false;
        const result = res.data;
        result.forEach(item => {
          this.runningTime.push({ id: item.id, name: item.name, value: item.value.runningTime ? (item.value.runningTime / 60).toFixed(2) : 0 });
          this.runningCount.push({ id: item.id, name: item.name, value: item.value.runningCount });
          this.runDistance.push({ id: item.id, name: item.name, value: item.value.runDistanceOfKilometers });
          this.doorOpenCount.push({ id: item.id, name: item.name, value: item.value.doorOpenCount });
          this.onlineRate.push({ id: item.id, name: item.name, value: item.value.onlineRate });
          this.health.push({ id: item.id, name: item.name, value: item.value.health });
          this.repairCount.push({ id: item.id, name: item.name, value: item.value.overhauCount });
          this.faultStop.push({ id: item.id, name: item.name, value: item.value.faultStopCount, accumulatedFaultCount: item.value.accumulatedFaultCount });
          this.faultTrap.push({ id: item.id, name: item.name, value: item.value.faultTrappingCount });
          this.faultStopTime.push({ id: item.id, name: item.name, value: item.value.faultStopTime });
          this.overhaulTime.push({ id: item.id, name: item.name, value: item.value.overhaulTime });
          this.faultRate.push({ id: item.id, name: item.name, value: item.value.faultRate });
        });
        this.runningTimeMax = this.runningTime.reduce((max, current) => {
          return (max.value > current.value) ? max : current;
        }, this.runningTime[0]);
        this.runningCountMax = this.runningCount.reduce((max, current) => {
          return (max.value > current.value) ? max : current;
        }, this.runningCount[0]);
        this.runDistanceMax = this.runDistance.reduce((max, current) => {
          return (max.value > current.value) ? max : current;
        }, this.runningCount[0]);
        this.runningCountTotal = this.runningCount.reduce((a, b) => {
          return a + b.value;
        }, 0);
        this.runDistanceTotal = this.runDistance.reduce((a, b) => {
          return a + b.value;
        }, 0);
        this.doorOpenCountTotal = this.doorOpenCount.reduce((a, b) => {
          return a + b.value;
        }, 0);
        this.faultCountTotal = this.faultStop.reduce((a, b) => {
          return a + b.accumulatedFaultCount;
        }, 0);
      });
    },

    // 获取故障类型
    getFaultType() {
      const params = {
        elevatorIdSet: this.elevatorIdSet,
        startTime: this.reportDate[0],
        endTime: this.reportDate[1]
      };
      this.$http.post("maintenanceReport/getFaultTypeTopByMultiple", params).then(res => {
        const newArr = res.data.map(item => {
          return { value: item.total, name: item.name };
        });
        this.faultType = newArr.sort((a, b) => b.total - a.total);
      });
    },

    // 下载
    downLoad() {
      window.print();
    },

    // 修改统计周期
    dateChange(date) {
      this.reportDate = date;
    },

    // 摘要引言编辑
    edit() {
      this.dialogVisible = true;
      this.newAbstract = this.abstract;
    },

    sureHandle() {
      this.abstract = this.newAbstract;
      this.dialogVisible = false;
    },
  }
};
</script>

<style lang="scss" scoped>
.multiElevatorReport {
  min-height: 100%;
  background-color: #f2f2f2;
  padding-top: 15px;
  font-size: 14px;
  box-sizing: border-box;

  .blue {
    color: #0747FD;
    font-weight: 700;
  }

  .red {
    color: red;
    font-weight: 700;
  }

  p {
    margin: 12px 0;
  }

  .reportContent {
    width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    color: #4D4D4D;
    border-radius: 12px;
    overflow: hidden;
    padding-bottom: 15px;

    .grid-content {
      background-color: #F5F8FF;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 16px;
      color: #4D4D4D;

      .itemValue {
        font-size: 24px;
      }
    }

    .title {
      height: 64px;
      background-color: #0747FD;
      line-height: 64px;
      color: #fff;
      text-align: center;
      position: relative;

      span {
        font-size: 20px;
        font-weight: bold;
      }

      .downLoadBtn {
        position: absolute;
        right: 24px;
        background-color: #00C291;
        color: #fff;
        top: 50%;
        transform: translateY(-50%);
        border: none;
      }
    }

    .reportDate {
      text-align: right;
      padding: 20px 24px;

      .range {
        color: #4D4D4D;
      }

      .datePicker {
        margin: 0 12px;
      }

      .createReport {
        background-color: #0747FD;
        color: #fff;
        border: none;
      }
    }

    .abstract {
      padding: 0 24px;
    }

    .smallTitle {
      width: 260px;
      height: 48px;
      background-color: #00C291;
      border-radius: 0px 10px 50px 0px;
      color: #fff;
      display: flex;
      align-items: center;
      padding-left: 24px;
      box-sizing: border-box;
      margin-top: 40px;
    }

    .footer {
      display: flex;
      justify-content: end;
      padding: 0 24px;
      text-align: center;

      .signBox {
        box-sizing: border-box;
        padding-top: 12px;
        margin: 24px 0;
        width: 400px;
        height: 120px;
        border: 1px solid #CCCCCC;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

// 打印样式
@media print {

  @page { 
    margin: 0 !important; /* 设置打印时的每页边距为无 */
  }

  .multiElevatorReport {
    padding: 0;

    .createReport,
    .downLoadBtn {
      display: none;
    }
  }

  .prevent-page-break-inside {
    page-break-inside: avoid;
  }
}
</style>