<template>
  <div class="echartItemMulti" v-if="isShow">
    <div class="echartTitle">故障率</div>
    <div id="multiFaultRate" class="echartBox"></div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  data() {
    return {
      isShow: false
    };
  },

  watch: {
    data: {
      handler(value) {
        // const result = value.some(item => item.value !== 0);
        if (value.length > 0) {
          this.isShow = true;
          this.init();
        } else {
          this.isShow = false;
        }
      }
    }
  },

  methods: {
    init() {
      this.$nextTick(() => {
        const myChart = this.$echarts.init(document.getElementById("multiFaultRate"));
        const option = {
          title: {
            subtext: "单位：PPM，百万分之",
            left: 7
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow"
            },
            formatter: "{b}<br />{c}PPM"
          },
          grid: {
            left: "30",
            right: "48",
            bottom: "0%",
            top: "15%",
            containLabel: true
          },
          xAxis: {
            type: "category",
            triggerEvent: true,
            data: this.data.map(item => item.id),
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              formatter: (value) => {
                const item = this.data.find(item => item.id == value);
                if (item) {
                  return item.name.slice(0, 8) + "...";
                } else {
                  return "";
                }
              }
            },
          },
          yAxis: {
            type: "value",
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            }
          },
          series: [
            {
              type: "bar",
              barWidth: 10,
              data: this.data.map(item => ({
                id: item.id,
                value: item.value,
                name: item.name,
                itemStyle: {
                  color: "#B4C7FF",
                }
              })),
              markLine: {
                symbol: [],
                data: [{
                  type: "average",
                  name: "平均值"
                }],
                lineStyle: {
                  color: "#B4C7FF",
                  type: "solid"
                }
              },
              label: {
                show: true,
                position: "start",
                rich: {
                  line: {
                    backgroundColor: "#0747FD",
                    fontSize: 2,
                    width: 10
                  },
                },
                formatter: function (params) {
                  if (params.value) {
                    return "{line|}";
                  } else {
                    return "";
                  }
                }
              },
            }
          ]
        };
        myChart.setOption(option);

        myChart.on("click", (params) => {
          if (params.componentType === "xAxis") {
            const fullPath = this.$router.resolve({ name: "singleElevatorReport", params: { id: params.value } }).href;
            window.open(fullPath, "_blank");
          } else if (params.componentType === "series") {
            const fullPath = this.$router.resolve({ name: "singleElevatorReport", params: { id: params.data.id } }).href;
            window.open(fullPath, "_blank");
          }
        });
      });
    }
  }
};
</script>